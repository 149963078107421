<template>
  <v-dialog v-model="dialog" width="80vw">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-2" outlined color="primary" v-bind="attrs" v-on="on">
        View
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="">
        <span>{{ content.title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon color="indigo" @click="dialog = false">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-html="content.content"> </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            (role === 'admin' || role === 'corporate') && content.isArchived
          "
          class="ma-2"
          outlined
          color="indigo"
          @click="sendRestore(content._id)"
        >
          Restore
        </v-btn>
        <v-btn class="ma-2" outlined color="indigo" @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BulletinModal",
  props: {
    content: Object
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    role() {
      return this.$store.state.user.role;
    }
  },
  methods: {
    sendRestore(id) {
      this.$emit("restore", id);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss">
.v-dialog {
  max-width: 1100px;
}
</style>
