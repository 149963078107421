<template>
  <v-container fluid class="primary" style="overflow-y: scroll">
    <v-row style="margin-bottom: 0px; padding-bottom: 0px;" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-around">
        <v-card class="mb-3">
          <div class="d-flex justify-space-between">
            <v-card-title> Welcome to RTB Connect! </v-card-title>

            <div class="pa-4 ">
              Questions? Please contact us at
              <a href="mailto:rtbconnect@relaxtheback.com"
                >rtbconnect@relaxtheback.com</a
              >
            </div>
          </div>

          <hr />
          <v-card-actions>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on">
                  Marketing
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in categories.marketing"
                  :key="index"
                  :to="{ name: 'Documents', params: { category: item } }"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on">
                  Merchandising
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in categories.merchandising"
                  :key="index"
                  :to="{ name: 'Documents', params: { category: item } }"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on">
                  OPS
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in categories.ops"
                  :key="index"
                  :to="{ name: 'Documents', params: { category: item } }"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              color="primary"
              text
              :to="{ name: 'Documents', params: { category: 'Product Cards' } }"
            >
              Product Cards
            </v-btn>

            <v-btn
              color="primary"
              text
              :to="{
                name: 'Documents',
                params: { category: 'Training' }
              }"
            >
              Training
            </v-btn>

            <v-btn
              color="primary"
              text
              :to="{
                name: 'Documents',
                params: { category: 'Warranties' }
              }"
            >
              Warranties
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>

         
        </v-card>

        <!-- class="important-announcements" -->

        <v-card
          v-if="compBulletins.length >= 1"
          elevation="1"
          class="mb-1 "
          color="#202C4A"
          dark
        >
          <v-card-title color="white"
            ><v-icon large color="yellow" class="mr-2">{{ "mdi-star" }}</v-icon>
            Important Announcements</v-card-title
          >
          <!-- <table style="width: 100%; margin: 0 15px;">
          <tr v-for="bulletin in compBulletins" :key="bulletin._id">
            <td>
              <v-icon color="red" v-if="bulletin.isUrgent">mdi-information</v-icon>
              {{ bulletin.title }}
            </td>
            <td>{{ friendlyDate(bulletin.createdOn) }}</td>
            <td>
              <bulletin-modal :content="bulletin" />
            </td>
          </tr>
              


            </table> -->
          <v-data-table
            light
            color="#385F73"
            :headers="headersBulletins"
            :items="compBulletins"
            :items-per-page-options="[5, 10, 20]"
            :options="{ itemsPerPage: 5 }"
          >
            <template v-slot:item.title="{ item }">
              <v-icon color="red" v-if="item.isUrgent">mdi-information</v-icon>
              {{ item.title }}
            </template>
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <bulletin-modal :content="item" />
            </template>
          </v-data-table> 
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ml-12 no-gutters pl-3">
      <cmsCard v-for="(card, index) in compCms" :key="card._id" :cms="card" :index="index" />
    </v-row>

    <!-- <v-row class="ml-12"> -->
    <!--   <v-col v-for="card in compCms" :key="card._id" :cols="card.width"> -->
    <!--     <v-card elevation="1" class="dashboard-cms "> -->
    <!--       <v-img -->
    <!--           :src="randomImage()" -->
    <!--           class="white--text align-end" -->
    <!--           gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)" -->
    <!--           height="350px" -->
    <!--         > -->
    <!--           <v-card-title v-text="card.name"></v-card-title> -->
    <!--         </v-img> -->
    <!--       <!-- <v-card-text v-html="card.cms" class="ql-editor"> </v-card-text> -->
    <!---->
    <!--       <v-card-actions> -->
    <!--   <v-btn -->
    <!--     color="orange lighten-2" -->
    <!--     text -->
    <!--   > -->
    <!--     Details -->
    <!--   </v-btn>  -->
    <!---->
    <!--   <v-spacer></v-spacer> -->
    <!---->
    <!--   <v-btn -->
    <!--     icon -->
    <!--     @click="show5 = !show5" -->
    <!--   > -->
    <!--     <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> -->
    <!--   </v-btn> -->
    <!-- </v-card-actions> -->
    <!---->
    <!-- <v-expand-transition> -->
    <!--   <div v-show="show5"> -->
    <!--     <v-divider></v-divider> -->
    <!---->
    <!--     <v-card-text v-html="card.cms" class="ql-editor"> </v-card-text> -->
    <!---->
    <!--   </div> -->
    <!-- </v-expand-transition> -->
    <!--     </v-card> -->
    <!--   </v-col> -->
    <!-- </v-row> -->

    <v-row style="height: 100vh" class="ml-6 no-gutters pl-3">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card v-if="compFavorites.length >= 1" elevation="1" class="mb-3">
          <v-card-title>Commonly Used Files</v-card-title>
          <v-card-subtitle>Our most popular documents</v-card-subtitle>
          <v-data-table :headers="headers" :items="compFavorites">
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                class="ma-2"
                icon
                color="indigo"
                @click="logActivity(item.title)"
                :href="item.URL"
                target="_blank"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-card v-if="compRecent.length >= 1" elevation="1" class="mb-6">
          <v-card-title>What's New</v-card-title>
          <v-card-subtitle>Recently shared documents</v-card-subtitle>
          <v-col cols="12" sm="4"
            ><v-select
              v-model="dateRange"
              :items="dateRanges"
              label="Date"
              clearable
            ></v-select
          ></v-col>
          <v-data-table :headers="headers" :items="compRecent">
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="logActivity(item.title)"
                :href="item.URL"
                target="_blank"
              >
                Download
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-card
          v-if="compMostDownloaded.length >= 1"
          elevation="1"
          class="mb-3"
        >
          <v-card-title>Popular Downloads</v-card-title>
          <v-card-subtitle>Most downloaded documents</v-card-subtitle>
          <v-data-table :headers="headers" :items="compMostDownloaded">
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                class="ma-2"
                icon
                color="indigo"
                @click="logActivity(item.title)"
                :href="item.URL"
                target="_blank"
              >
                                <v-icon>mdi-download</v-icon>

              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import moment from "moment";
import helpers from "@/plugins/helpers";
import BulletinModal from "@/components/BulletinModal";
import cmsCard from "@/components/cmsCard.vue";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
export default {
  name: "Dashboard",
  data: () => ({
    show: false,
    show2: false,
    show3: false,
    show4: false,
    show5: false,
    headers: [
      { text: "Title", value: "title" },
      { text: "Category", value: "category" },
      { text: "Date", value: "createdOn" },
      { text: "Uploaded By", value: "author" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    headersBulletins: [
      { text: "Title", value: "title", width: "40%" },
      { text: "Category", value: "category", width: "30%" },
      { text: "Date", value: "createdOn" },
      { text: "Added By", value: "author" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    headersMostDownloaded: [
      { text: "Title", value: "title" },
      { text: "Download", value: "document" }
    ],
    categories: {
      marketing: [
        "Common Marketing Links",
        "Marketing News",
        "RTB Logos",
        "Synchrony Tran Codes"
      ],
      merchandising: [
        "Catalog Content/Vendor Promotions",
        "Conventions/Trade Shows/Events",
        "Core Product Assortment",
        "Customer Service Claim Form",
        "New Product Expo Information",
        "Medical Insurance Information",
        "Product Cards",
        "Product Image Guide",
        "Product Videos",
        "RTB Store Floor Plans",
        "Vendor Program Information",
        "Visual Merchandising",
        "Training",
        "Warranties"
      ],
      ops: [
        "Company Contact List",
        "FRS Quick Reference Guides",
        "OPS Manual",
        "RTB Connect Training Video"
      ]
    },
    dateRange: "Last 24 Hours",
    dateRanges: ["Last 24 Hours", "Last 7 Days", "Last 30 Days"],
    cards: [
      {
        title: "Pre-fab homes",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12
      },
      {
        title: "Favorite road trips",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
        flex: 6
      },
      {
        title: "Best airlines",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 6
      },
      {
        title: "Best airlines2",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 3
      },
      {
        title: "Best airlines3",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 6
      },
      {
        title: "Pre-fab homes4",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12
      }
    ],
    downloaded: []
  }),
  components: {
    BulletinModal,
    cmsCard
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    dashboard() {
      return this.$store.state.cms.dashboard;
    },
    compFavorites() {
      let data = this.$store.state.documents;
      data = data.filter(d => d.isFavorite === true);
      return data;
    },
    compRecent() {
      let data = this.$store.state.documents;
      let dateStart = moment();
      let dateEnd = moment();
      switch (this.dateRange) {
        case "Last 24 Hours":
          dateStart = moment().subtract(1, "days");
          break;
        case "Last 7 Days":
          dateStart = moment().subtract(7, "days");
          break;
        case "Last 30 Days":
          dateStart = moment().subtract(30, "days");
          break;
      }
      data = data.filter(d => moment(d.date).isBetween(dateStart, dateEnd));
      return data;
    },
    compBulletins() {
      let data = this.$store.state.bulletins;
      // remove archived
      data = data.filter(d => d.isArchived === false);
      // sort by date desc
      data.sort((a, b) => {
        let da = new Date(a.createdOn),
          db = new Date(b.createdOn);
        return db - da;
      });
      // move isUrgent to the top of the list
      data.sort((a, b) => {
        return b.isUrgent - a.isUrgent;
      });
      return data;
    },
    compCms() {
      let panels = this.$store.state.cms;
      panels.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
      return panels;
    },
    compMostDownloaded() {
      if (this.downloaded) {
        let downloads = this.downloaded;
        let documents = this.$store.state.documents;
        let mostDownloaded = [];
        downloads.forEach(d => {
          // loop downloads and find match in documents
          let index = documents.findIndex(element => {
            if (element.title.trim() === d.title.trim()) {
              return true;
            }
          });
          if (documents[index]) {
            mostDownloaded.push(documents[index]);
          }
        });
        console.log(mostDownloaded);
        return mostDownloaded;
      }
      return null;
    }
  },
  methods: {
    randomImage() {
      let img1 = require("@/assets/images/bg1.jpg");
      let img2 = require("@/assets/images/bg2.jpg");
      let img3 = require("@/assets/images/bg3.jpg");
      let img4 = require("@/assets/images/bg4.jpg");

      let images = [img1, img2, img3, img4];
      return images[Math.floor(Math.random() * images.length)];
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    logActivity(title, action = "Downloaded") {
      let vm = this;
      // log this activity
      let activity = {
        user: {
          username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
          email: this.$store.state.user.username
        },
        date: moment().format("MM/DD/YYYY"),
        action: `${action}: ${title} `
      };
      helpers.log(vm.token, vm.$root.urls.api, activity);
      return;
    },
    getMostDownloaded() {
      let vm = this;
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios
        .get(vm.$root.urls.api + "/documents/downloaded", {})
        .then(function(results) {
          vm.downloaded = results.data;
        });
    },
    parseDownloadURL(title) {
      return `${window.origin}/documents/${title}`;
    }
  },
  mounted: function() {
    let documents = this.$store.state.documents;
    console.log(`documents length: ${documents.length}`);

    // get most downloaded
    this.getMostDownloaded();
    // align CMS panels
    let rows = [];
    let panels = document.querySelectorAll(".dashboard-cms");
    panels.forEach(p => {
      rows.push({ offsetTop: p.offsetTop, height: p.clientHeight });
    });
    // loop panels again, and match height if needed
    panels.forEach(p => {
      rows.forEach(r => {
        if (r.offsetTop === p.offsetTop && r.height > p.clientHeight) {
          p.setAttribute("style", `height: ${r.height}px`);
        }
      });
    });
  }
};
</script>

<style lang="scss">
.important-announcements {
  font-weight: bold;
  background-color: #202C4A;
  color: #e60001;
  border-bottom: 1px solid darken(#fdff00, 5%);
}
</style>
