<template>
  <v-col cols="12" class="mb-4">
    <!-- <v-alert v-if="cms.cms.includes('youtube.com')" type="info">
      This is a video. Here are the video ids: {{ extractYoutubeIDs(cms.cms) }}
    </v-alert> -->

    <!-- <div v-if="cms.cms.includes('youtube.com')">
      <YouTubeButtons :htmlContent="cms.cms" />
    </div> -->

    <v-card elevation="1" class="dashboard-cms ">
      <v-row
        no-gutters
        class="d-flex"
        v-bind:class="{ 'flex-row-reverse': index % 2 === 0 }"
      >
        <v-col cols="8">
          <v-card-text v-if="cms.cms.includes('youtube.com')">
            <YouTubeButtons :htmlContent="cms.cms" />
          </v-card-text>
          <v-card-text v-else v-html="cms.cms" class="ql-editor"> </v-card-text>
        </v-col>
        <v-col
          cols="4"
          :style="{ backgroundImage: 'url(' + bgImage + ')' }"
          class="image-container"
        >
          <div class="overlay">
            <v-card-title v-text="cms.name" style="color:white;"></v-card-title>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import YouTubeButtons from "@/components/YouTubeButtons.vue";
export default {
  data() {
    return {
      show: false,
      bgImage: ""
    };
  },
  props: {
    cms: Object,
    index: Number
  },
  components: {
    YouTubeButtons
  },
  methods: {
    randomImage(img) {
      let img1 = require("@/assets/images/bg1.jpg");
      let img2 = require("@/assets/images/bg2.jpg");
      let img3 = require("@/assets/images/bg3.jpg");
      let img4 = require("@/assets/images/bg4.jpg");
      let img5 = require("@/assets/images/bg5.jpg");
      let img6 = require("@/assets/images/bg6.jpg");
      let img7 = require("@/assets/images/bg7.jpg");
      let img8 = require("@/assets/images/bg8.jpg");

      let images = [img1, img2, img3, img4, img5, img6, img7, img8];

      if (img) {
        let imageId = img.replace(".jpg", "");
        imageId = imageId.replace("bg", "");
        return images[Number(imageId) - 1];
      }

      return images[Math.floor(Math.random() * images.length)];
    },
    extractYoutubeIDs(htmlContent) {
      const regex = /youtube\.com\/embed\/([^?"]+)/g;
      const matches = htmlContent.matchAll(regex);
      return Array.from(matches, match => match[1]);
    }
  },
  mounted() {
    this.bgImage = this.randomImage(this.cms.image);
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  min-height: 200px; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.7)
  ); /* Adjust the opacity as needed */
}
</style>
