<template>
  <div
    class="d-flex flex-wrap  justify-space-around align-center"
    style=""
  >
    <v-card v-for="video in videos" :key="video.id" class="video-item pa-2 ma-2" style="cursor: pointer">
      <img  @click="openModal(video.id)" :src="video.thumbnail" :alt="video.title" class="video-thumbnail" />
      <v-btn @click="openModal(video.id)" color="primary" depressed block x-small>
        {{ video.title.substring(0, 35) }}
      </v-btn>
    </v-card>

    <YouTubeModal
      :videoId="currentVideoId"
      :isOpen="isModalOpen"
      @close="closeModal"
    />

    <!-- <v-btn -->
    <!--   v-for="videoId in videoIds" -->
    <!--   :key="videoId" -->
    <!--   @click="openModal(videoId)" -->
    <!--   color="primary" -->
    <!--   outlined -->
    <!--   text -->
    <!-- > -->
    <!--   Play Video -->
    <!-- </v-btn> -->
    <!---->
    <!-- <YouTubeModal -->
    <!--   :videoId="currentVideoId" -->
    <!--   :isOpen="isModalOpen" -->
    <!--   @close="closeModal" -->
    <!-- /> -->
  </div>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import YouTubeModal from "@/components/YouTubeModal.vue";

export default {
  name: "YouTubeButtons",
  components: {
    YouTubeModal
  },
  props: {
    htmlContent: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      videos: [],
      currentVideoId: "",
      isModalOpen: false
    };
  },
  mounted() {
    this.extractYoutubeIDs();
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    }
  },
  methods: {
    extractYoutubeIDs() {
      const regex = /youtube\.com\/embed\/([^?"]+)/g;
      const matches = this.htmlContent.matchAll(regex);
      const videoIds = Array.from(matches, match => match[1]);
      this.fetchVideoInfo(videoIds);
    },
    openModal(videoId) {
      this.currentVideoId = videoId;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.currentVideoId = "";
    },
    async fetchVideoInfo(videoIds) {
      const vm = this;
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };

      for (const id of videoIds) {
        try {
          const response = await axios.get(
            vm.$root.urls.api + `/api/youtube-video-info/${id}`
          );
          this.videos.push({
            id,
            title: response.data.title,
            thumbnail: response.data.thumbnail
          });
        } catch (error) {
          console.error("Error fetching video info:", error);
        }
      }
    }
  }
};
</script>

<style scoped>
.video-button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.video-button:hover {
  background-color: #45a049;
}
</style>
